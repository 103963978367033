@import '~@fortawesome/fontawesome-pro/css/all.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.scroll {
  position: relative;
  height: 100%;
  max-width: unset;
  width: unset;
  margin-right: -15px;
  padding-right: 15px;
}
.ng-select .ng-select-container .ng-value-container {
  font-size: 0.9rem !important;
}
.mat-mdc-table .mdc-data-table__row {
  height: 40px !important;
}
.invalid{
margin-top: -1rem;
}
form label, .form-group label {
  text-transform: capitalize !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: rgb(255, 255, 255) !important;
}

.modal-header {
  padding: 1rem 1rem 0rem 1rem !important;
}
.modal-title {
  padding: 1rem;
  margin: 0rem;
}
.modal-header .close {
  padding: 2rem 2rem !important;
}


.mat-mdc-paginator-page-size-label {
  display: none !important;
}

.mat-mdc-paginator-page-size{
  height: 35px !important;
}

.cdk-drag-preview {
  z-index: 9999999999 !important;
}
.nav-tabs .nav-link {
  color: #40C057 !important;
}
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #40C057 !important;
}
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.box-shadow {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px 0px rgba(0, 0, 0, 0.12) !important;
}
.mdc-menu-surface.mat-mdc-select-panel {
  min-width: 225px !important;
}
.mat-mdc-paginator-range-label {
  margin: 0px !important;
}
.mat-mdc-paginator-container {
  padding: 0px !important;
}
.ngx-file-drop__drop-zone{
  border: 2px dotted black !important;
  background: #f0f3f5;
  height: 150px !important;
}
.ngx-file-drop__content{
  color: #626e8a !important;
  height: 150px !important;
}

.custom-btn-position {
  display: flex !important;
  flex-direction: row-reverse;
}
.pac-container {
  z-index: 6000 !important;
}

.yellow {
  background-color: yellow;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}