/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/material.theme.css";
/* @import '~@angular/material/prebuilt-themes/indigo-pink.css'; */

.c-pointer {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

.custom-table {
    max-height: 40rem;
}

/* body {
    overflow: hidden;
} */

.truncate {
    /* width: 9rem !important; */
    width: auto !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    /* text-overflow: ellipsis !important; */
}

.e-table th {
    /* padding: 10px !important; */
    /* line-height: 0px; */
    background-color: white !important;
}

table th,
table td {
    padding: 5px !important;
    vertical-align: middle !important;
    /* border-top: 1px solid #E0E0E0;  */
}

/* html, body { height: 100%; } */
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


.mdc-list-item__content {
    text-overflow: inherit !important;
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
    flex: 1;
    pointer-events: none;
}

.mat-mdc-table .mdc-data-table__header-row {
    height: 35px !important;
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px 0px rgba(0, 0, 0, 0.12) !important;
}

.mat-header-row {
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px 0px rgba(0, 0, 0, 0.12) !important;
}

.no-bottom {
    margin-bottom: -1.25em !important;
}

mat-form-field {
    margin-top: 10px;
    margin-bottom: -1.25em !important;
}